const logotext = "BRENT OGDEN";
const meta = {
    title: "Brent Ogden",
    description: "I am a technology-driven individual who excels in fast paced work environments. I enjoy operating independently, or as part of a team working together to achieve a common goal. I pride myself in my ability to learn new skills quickly and to complete a given task or project with efficiency and accuracy. I am constantly looking for ways to improve a process or workflow in an effort to increase productivity and do not shy away from learning new skills on my own to achieve that goal. ",
};

const introdata = {
    title: "My Name is Brent Ogden",
    animated: {
        first: "I love technology",
        second: "Websites and coding are my thing",
        third: "Python and React get me excited",
    },
    description: "I am a technology-driven individual who excels in fast paced work environments. I enjoy operating independently, or as part of a team working together to achieve a common goal. I pride myself in my ability to learn new skills quickly and to complete a given task or project with efficiency and accuracy. I am constantly looking for ways to improve a process or workflow in an effort to increase productivity and do not shy away from learning new skills on my own to achieve that goal.",
    your_img_url: "https://i.ibb.co/85G2cgz/brentimage.jpg",
};

const dataabout = {
    title: "My Story...",
    aboutme: "I am a Colorado native - born in Denver - with a love for the outdoors and for all things tech. In my spare time you can find me snowboarding in the winters and hiking in the summers. I absolutely love sports and music and am a life-long, die-hard Broncos fan. From December until May, you will find me at the 'Loud House' cheering on the Colorado Mammoth. I am always looking for an opportunity to work somewhere that offers an ideal work/life balance so that I can enjoy these activities while still helping my team and company succeed. ",
};
const worktimeline = [{
        id: 1,
        jobtitle: "Senior Software Engineer | Dish Network",
        where: "Littleton, CO",
        date: "Oct ‘22 – Present",
        jobDescription: "<li>Spearheaded a project to design, research and develop a Python-based ML desktop application that was deployed to 148 users in the Legal department.</li><li>Earned a Quarterly Innovation Award for creating Python automation scripts to streamline time consuming manual processes and reduce workload from 1 week to a few hours.</li><li>Mentored junior engineers, improved processes, and facilitated contract management by configuring contract types and templates for end-users.</li><li>Resolved complex production application issues and verified process success by conducting audits on Contract Lifecycle Management projects.</li><li>Designed and implemented changes to the CLM software based on Business/Technical Requirements.</li><li>Provided expert guidance to business partners and other IT resources regarding application and process matters.</li><li>Prepared targeted documentation for executives and architecture teams, including design considerations, interface specifications, and proof-of-concepts.</li>",
    },
    {
        id: 2,
        jobtitle: "Configuration Analyst | Welltok/Virgin Pulse",
        where: "Denver, CO",
        date: "Jan ‘20 – Sep ‘22",
        jobDescription: "<li>Principle contact for the Business Analysts and Service Delivery Managers</li><li>Became the SME for our Airtable project management tool - created new processes/workflows and optimized existing ones - trained new and existing SDMS/BAs/etc. on how to use the tool</li><li>Ran/modified/created Python scripts to automate various aspects of the configuration process to streamline these very manual and time-consuming processes.</li><li>Wrote an in-depth process document outlining AirTable processes and functions.</li><li>Updated code on 60+ marketing pages for a chief client to accommodate our new look and feel and code structure.</li>",
    },
    {
        id: 3,
        jobtitle: "Associate Configuration Analyst | Welltok",
        where: "Denver, CO",
        date: "Oct ‘18 – Jan ‘20",
        jobDescription: "<li>Assisted in configuring assets and programs for client campaigns, using both proprietary CMS tools and HTML/CSS</li><li>Worked with BAs (Business Analysts) and SDMs (Service Delivery Managers) to ensure that client requirements were properly documented and realized in the final product.</li><li>Assisted the configuration team with the configuration of Jumbotrons, Promos, Marketing pages,Navigation items, ActionCards, etc. in alignment with the client's program designs.</li><li>Built a microsite to house training videos that I and the rest of the configuration team created</li>",
    },
    {
        id: 4,
        jobtitle: "Web Developer | First Data Corporation",
        where: "Greenwood Village, CO",
        date: "Apr ‘15 – Dec ‘17",
        jobDescription: "<li>Coded several websites using HTML5/Handlebars/PHP as well as 3rd party CMS tool - Adobe Experience Manager</li><li>Identified and suggested new technologies and tools for enhancing product value and increasing team productivity.</li><li>Maintained existing websites and design and deliver new responsive templates.</li><li>Converted several existing PHP websites for use with Static Platform - Apache server on AWS using Grunt/Handlebars</li><li>Converted PSD files provided by marketers into responsive, bootstrap-based HTML5 websites, utilizing several task runners and preprocessors - SASS/Grunt/Gulp</li>",
    },
    {
        id: 5,
        jobtitle: "Associate Web Program Manager | First Data Corporation",
        where: "Greenwood Village, CO",
        date: "Mar ‘13 – Apr ‘15",
        jobDescription: "<li>Administered the creation, support, and implementation of BoldChat, live chat provider used on 100's of sites.</li><li>Uploaded videos, create custom players, and handle administration of Brightcove to manage video content across 100's of sites.</li><li>Redesigned and administered SharePoint site for use by the Web Team</li><li>Purchased, configured, and transferred hundreds of domains - including submitting DNS requests and facilitating transfer of domains into centralized account.</li><li>One of two system admins for project management tool - Workfront - created new request forms for various teams within the organization to streamline processes and improve efficiencies.</li><li>Assisted with content management for various sites - using Adobe Experience Manager CMS tool.</li>",
    },
    {
        id: 6,
        jobtitle: "Quality Assurance Lead | National Cinemedia",
        where: "Centennial, CO",
        date: "Jul ‘11 – Mar ‘13",
        jobDescription: "<li>Performed regression testing and ongoing maintenance of all consumer-facing entities (7 websites, 2 mobile apps, and department SharePoint site)</li><li>Tested and maintained both mobile applications across both iOS and Android platforms.</li><li>Worked with the Project Management team, Content Administration team, and Interactive Development team continually from inception/discovery to completion.</li><li>Entered, tracked, monitored, and assigned tasks into TFS (Team Foundation Server) to facilitate workloads for all teams.</li><li>Ongoing communication with off-site mobile development team in Argentina to facilitate upgrades/enhancements/new functionality and resolve bugs - extensive use of Pivotal Tracker</li><li>In charge of crash report tracking, app store and website customer feedback, bi-weekly release highlight correspondence, and several other inter-department communications.</li>",
    },
    {
        id: 7,
        jobtitle: "Production Coordinator | National Cinemedia",
        where: "Centennial, CO",
        date: "Dec ‘05 – Aug ‘11",
        jobDescription: "<li>Managed the production process from start to finish (handling creative media/orders from point of receipt to finalization)</li><li>Administered most widely used database in the company (monitor and fix issues, make improvements/enhancements, handle questions, implement upgrades, etc.)</li><li>Created/maintained several reports in MS Access used by VPs and several other departments to monitor vital information.</li><li>Worked with small team to ensure 300 - 800 ads per week were completed, approved, and uploaded by the deadline.</li>",
    },
];

const skills = [{
        name: "Python",
        value: 90,
        imageUrl: "https://i.ibb.co/VBttgrG/python.png",
    },
    {
        name: "HTML5",
        value: 85,
        imageUrl: "https://i.ibb.co/TtprXmD/html5-icon-4.png",
    },
    {
        name: "Javascript",
        value: 80,
        imageUrl: "https://i.ibb.co/WGGRKts/javascript.png",
    },
    {
        name: "React",
        value: 60,
        imageUrl: "https://i.ibb.co/S75TFZV/react2.png",
    },
    {
        name: "Jquery",
        value: 85,
        imageUrl: "https://i.ibb.co/tbG0DkR/jquery.png",
    },
    {
        name: "PHP",
        value: 85,
        imageUrl: "https://i.ibb.co/Z2KyCkg/php.png",
    },
    {
        name: "GitHub",
        value: 85,
        imageUrl: "https://i.ibb.co/ZBvvK5c/githublogo.png",
    },
    {
        name: "CSS3",
        value: 85,
        imageUrl: "https://i.ibb.co/TPSvsjv/css-3.png",
    },
    {
        name: "Node.js",
        value: 85,
        imageUrl: "https://i.ibb.co/BrffWLt/nodelogo.png",
    },
];

const services = [{
        title: "Responsive Web Design",
        description: "I have multiple years of experience building fully-responsive websites using Bootstrap. I know that today's mobile-first world requires sites to be built with a primary focus on mobile and must be able to adapt to any screen size. ",
    },
    {
        title: "Python",
        description: "Most recently, I have been very involved with Python at both my current and previous job, using it for UI automation as well as full application development. I have written many scripts to automate tedious tasks for our web-based tools, and recently deployed a desktop application that will be used by the entire Legal Department.",
    },
    {
        title: "Adobe Experience Manager",
        description: "I have past experience creating/updating/customizing sites in Adobe Experience Manager. During that time, I was also involved in translating those sites into several different languages.",
    },
    {
        title: "Wordpress Design",
        description: "Early on in my career, I dealt primarily in Wordpress and also built several marketing landing pages in PHP for various clients. Many of my early personal sites were Wordpress based, and I have used some PHP based contact forms on other sites that I have built.",
    },
];

const dataportfolio = [{
        img: "https://i.ibb.co/1MBnX6r/jbsimplyclean.jpg",
        description: "I built this professional, fully-responsive site for a friend's business. It has user account creation, password reset, and email functionality.",
        link: "https://jbsimplyclean.com/",
    },
    {
        img: "https://i.ibb.co/Kx3h1rf/portfolio.jpg",
        description: "The first portfolio site that I built 6 years ago as a one page, highly-visual showcase of my work.",
        link: "https://milehighmashup.com/portfolio/",
    },
    {
        img: "https://i.ibb.co/M1rkj9V/Fantasy-Central.jpg",
        description: "A companion site for the two fantasy football leagues that I run - recently rebuilt in React.",
        link: "https://fantasycentral.co/",
    },
    {
        img: "https://i.ibb.co/chhD6Wc/Mile-High-Mashup.jpg",
        description: "I rebuilt an old single page personal site using React. The site consumes live score and other sports APIs to display scores and game info for different teams.",
        link: "https://milehighmashup.brentogden.com/",
    },
    {
        img: "https://i.ibb.co/TrHYbx7/gyft-main-min.png",
        description: "A page that I built at a previous job using Adobe Experience Manager and custom CSS/JS.",
        link: "https://merchants.fiserv.com/en-us/products/merchants/gift-and-payroll-card-solutions/gift-card-solutions/?utm_source=firstdataus",
    },
    {
        img: "https://i.ibb.co/kJR8Qv4/rename.png",
        description: "Python desktop application built for Legal Department to rename PDFs and DOC files using OCR and ML.",
        link: "https://github.com/BrentOgden/ReName-main",
    },
    
    
    {
        img: "https://i.ibb.co/dsvcT0V/US-Debit-Card-Home-Page-Bureau-of-the-Fiscal-Service-U-S-Department-of-the-Treasury.jpg",
        description: "A client-specific microsite that I built for a previous job using PHP. Design was submitted through outside agency.",
        link: "https://www.usdebitcard.gov/",
    },

    // {
    //     img: "https://picsum.photos/400/600/?grayscale",
    //     description: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/300/?grayscale",
    //     description: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/?grayscale",
    //     description: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/550/?grayscale",
    //     description: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/?grayscale",
    //     description: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
    // {
    //     img: "https://picsum.photos/400/700/?grayscale",
    //     description: "The wisdom of life consists in the elimination of non-essentials.",
    //     link: "#",
    // },
];

const contactConfig = {
    YOUR_EMAIL: "ogden87@gmail.com",
    YOUR_FONE: "720.254.5354",
    description: "If you have any questions about my work or my resume, please reach out. I would love to discuss any potential opportunities.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_fhpw2pr",
    YOUR_TEMPLATE_ID: "template_ozgs3c2",
    YOUR_USER_ID: "DdNlb65683piFw_G_",
};

const socialprofils = {
    github: "https://github.com/BrentOgden",
    // facebook: "https://facebook.com",
    linkedin: "https://www.linkedin.com/in/brent-ogden-70398012",
    instagram: "https://instagram.com/MileHiRocks5280",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};