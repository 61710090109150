import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  dataabout,
  meta,
  worktimeline,
  skills,
  services,
} from "../../content_option";

const JobDetailsModal = ({ show, content, onHide }) => {
  const modalContentRef = React.useRef(null);

  const handleClickOutside = (event) => {
    // Prevent default behavior of the event
    event.preventDefault();

    // Close the modal if the click is outside of it
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      onHide();
    }
  };

  React.useEffect(() => {
    // Adding touchstart alongside mousedown for mobile devices
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Make sure to remove both event listeners
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <div ref={modalContentRef} className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close" onClick={(e) => { e.stopPropagation(); onHide(); }}
          onTouchStart={(e) => { e.stopPropagation(); onHide(); }}>&times;</button>

        {/* Render the HTML content */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};






export const About = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [animateProgress, setAnimateProgress] = useState(false);

  useEffect(() => {
    // Start the progress bar animation after the component mounts
    setAnimateProgress(true);
  }, []);
  // Function to handle clicking on a job title
  const handleClick = (jobDetail) => {

    const formattedContent = `
      <div style="padding: 20px;"><p><strong>${jobDetail.jobtitle}</strong></p>
      <p>Location: ${jobDetail.where}</p>
      <p>${jobDetail.jobDescription}</p></div>
    `;
    setModalContent(formattedContent);
    setShowModal(true);
  };
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> About | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">About me</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="4">
            <h3 className="color_sec py-4">{dataabout.title}</h3>
          </Col>
          <Col lg="8" className="d-flex align-items-center">
            <div>
              <p>{dataabout.aboutme}</p>
            </div>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="4">
            <h3 className="color_sec py-4">Where I've Been...</h3>
          </Col>
          <Col lg="8">
            <table className="table caption-top">
              <tbody>
                {worktimeline.map((data) => {
                  return (
                    <tr key={data.id}>
                      <th scope="row" onClick={() => handleClick(data)} className="job-title">
                        {data.jobtitle}
                      </th>
                      <td>{data.where}</td>
                      <td>{data.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>

        <Row className="sec_sp">
          <Col lg="12">
            <h3 className="color_sec py-4">My Top Skills...</h3>
            <div className="hexagon-layout">
              {/* First row */}
              {/* <div className="hex-row hex-row-1">
                <div className="hex" style={{ backgroundImage: `url(${skills[1].imageUrl})` }}></div>
              </div> */}
              {/* Second row */}
              <div className="hex-row hex-row-2">
                <div className="hex" style={{ backgroundImage: `url(${skills[5].imageUrl})` }}></div>
                <div className="hex" style={{ backgroundImage: `url(${skills[7].imageUrl})` }}></div>
              </div>
              {/* Third row */}
              <div className="hex-row hex-row-3">
                <div className="hex" style={{ backgroundImage: `url(${skills[0].imageUrl})` }}></div>
                <div className="hex" style={{ backgroundImage: `url(${skills[1].imageUrl})` }}></div>
                <div className="hex" style={{ backgroundImage: `url(${skills[2].imageUrl})` }}></div>
              </div>
              {/* Fourth row */}
              <div className="hex-row hex-row-2">
                <div className="hex" style={{ backgroundImage: `url(${skills[3].imageUrl})` }}></div>
                <div className="hex" style={{ backgroundImage: `url(${skills[4].imageUrl})` }}></div>
              </div>
              {/* Fifth row */}
              {/* <div className="hex-row hex-row-1">
                <div className="hex" style={{ backgroundImage: `url(${skills[7].imageUrl})` }}></div>
              </div> */}
            </div>




          </Col>
        </Row>


        <Row className="sec_sp">
          <Col lg="4">
            <h3 className="color_sec py-4">Where I Excel...</h3>
          </Col>
          <Col lg="8">
            {services.map((data, i) => {
              return (
                <div className="service_ py-4" key={i}>
                  <h5 className="service__title">{data.title}</h5>
                  <p className="service_desc">{data.description}</p>
                </div>
              );
            })}
          </Col>
        </Row>
        {showModal && (
          <JobDetailsModal
            show={showModal}
            content={modalContent}
            onHide={() => setShowModal(false)}
          />
        )}
      </Container>

    </HelmetProvider>

  );
};
